
body {
  margin-top: 300px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./Sofia6414.jpg");
  width: 100%;
  height: 600px;

}

.main{

  font-size: 20px;
  margin-top: 30px;
  font-family: "Cantarell";
}

.page{
  margin-left: 100px;
  margin-right: 100px;
  font-family: "Cantarell";
  margin-top:30px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
li{
  display: inline-block;
  margin-right: 30px;

}

.page-element{
  display: inline-block;
}

iframe{
  width: 400px;
  height: 180px;
}
.box1{
  background-color: white; 
  opacity: .5;
  padding: 30px;
  margin: 0 auto;
  width: 100%;
}
@font-face {
  font-family: "SmartFrocks";
  src: local("SmartFrocks"),
   url("./fonts/SmartFrocks/SmartFrocksNf-BdrG.ttf") format("truetype");
  font-weight: bold;
 }

 @media only screen and (max-width: 768px){
  body{
    background-image: url("./Sofia6414_phone.jpg");
  }

.box1{
  width: 90%
}

  ul{
    padding: 0;
    list-style-type: none;
  }
  .page{
    width: 95%;
    margin-left: 30px;
    margin-right: 30px;
  }

 }