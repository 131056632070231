.header{
    position: fixed;
    width: 100%;
  /* //  z-index: var (--z-fixed); */
  z-index: 1;
}


.nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
    margin:1rem;
    width: 100%;

}

.nav-link{
    font-family: "SmartFrocks";
    font-size: 50px;
    color: black;
}

.nav__logo {
    color: var(--first-color);
    transition: color 0.4s;
    font-size: var(--h2-font-size);
    margin-left: 100px;
    margin-top: 100px;
    width: 200px;
    height: 200px;

   }

.nav-list{
    display: flex;
    flex-direction: row;
    column-gap: 2.5rem;
    margin-left: 100px;
    
}

ul {
    list-style-type: none;
  }

  @media only screen and (max-width: 768px){
    .nav__logo{
        margin-left: 2px;
        width: 20px;
        height: 20px;
        padding-left: 0px;
    }
    .nav-list{
        margin-left: 2px;
        padding-left: 5px;
        column-gap: 0rem;
    }
    .ul {
        padding: 5px;
    }
    .nav-link{
        font-size: 35px;
    }
    .nav{
        align-items: left;
    }
    .logo{
        width: 40px;
        height: 40px;
    }
    .social{
        width: 10px; 
        height: 10px;
    }
    .nav-item{
        margin-left: 1rem;
    }
    .nav-socials{
        margin-left: 0.2rem;
    }

  }